import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';

import Header from './Header';
import Footer from './Footer';
import CookieConsentPopup from '../../legal/CookieConsentPopup';

import '../assets/styles/main-v3.scss';

const Layout = () => {
  // Add layout-v3 class to body.
  useEffect(() => {
    document.body.classList.add('layout-v3');

    return () => {
      document.body.classList.remove('layout-v3');
    };
  }, []);

  return (
    <div>
      <Header />
      <div className="container">
        <Outlet />
      </div>
      <Footer />

      <CookieConsentPopup className="cookie-consent-popup" />
    </div>
  );
};

export default Layout;
