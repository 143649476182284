import { useTranslation } from 'react-i18next';

import TrustedByPc from '../../../common/assets/images/main-trustedby-pc.png';
import TrustedByM from '../../../common/assets/images/main-trustedby-m.png';

const TrustedBy = () => {
  const { t } = useTranslation();

  return (
    <section className="trustedby">
      <article>
        <div className="title">
          <h1>Trusted By</h1>
          <p>{t('trustedby')}</p>
        </div>
        <picture>
          <source media="(min-width: 760px)" srcSet={TrustedByPc} />
          <img src={TrustedByM} alt="" />
        </picture>
      </article>
    </section>
  );
};

export default TrustedBy;
