import useScrollToTop from '../../common/hook/useScrollToTop';
import Contact from './partial/Contact';
import EthicalAi from './partial/EthicalAi';
import Main from './partial/Main';
import PublicationsAwards from './partial/PublicationsAwards';
import Technology from './partial/Technology';
import TrustedBy from './partial/TrustedBy';
import UseCases from './partial/UseCases';

const MainPage = () => {
  useScrollToTop();

  return (
    <>
      {/* Main */}
      <Main />

      {/* Technology */}
      <Technology />

      {/* Publications & Awards */}
      <PublicationsAwards />

      {/* Use Cases */}
      <UseCases />

      {/* Trusted By */}
      <TrustedBy />

      {/* Ethical AI */}
      <EthicalAi />

      {/* Contact */}
      <Contact />
    </>
  );
};

export default MainPage;
