import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import PoweredBySupertone from '../../../common/assets/logos/logo-poweredby-round-blue.svg';
import ProductClearImg from '../../../common/assets/images/main-product-clear.svg';
import ProductScreenplayImg from '../../../common/assets/images/main-product-screenplay.svg';
import ProductShiftImg from '../../../common/assets/images/main-product-shift.svg';

const Technology = () => {
  const { t } = useTranslation();

  return (
    <section className="technology">
      <article>
        <div className="title">
          <h1>Technology</h1>
          <p>
            <Trans i18nKey="tech1" />
          </p>
        </div>

        <div className="tech-research-area">
          <h2>Research Area</h2>
          <img src={PoweredBySupertone} alt="" className="poweredby" />
          <div className="keyword-wrap">
            <div>SINGING VOICE SYNTHESIS</div>
            <div>TEXT-TO-SPEECH SYNTHESIS</div>
            <div>GRAPHEME-TO-PHONEME</div>
            <div>MELODY/LYRICS TRANSCRIPTION</div>
            <div>STUDIO-QUALITY SPEECH ENHANCEMENT</div>
            <div>VOICE CONVERSION</div>
            <div>NATURAL LANGUAGE PROCESSING</div>
            <div>SPEAKER VERIFICATION</div>
            <div>AUTOMATIC SPEECH RECOGNITION</div>
          </div>
        </div>

        <div className="tech-product-lineup">
          <h2>Voice Product System</h2>

          <div className="product-wrap">
            <div className="product-logo">
              <img
                src={ProductScreenplayImg}
                alt=""
                className="logo-screenplay"
              />
            </div>
            <div className="product-text">
              <h3>Text to Speech</h3>
              <p>{t('tech1')}</p>
            </div>
          </div>

          <div className="product-wrap">
            <div className="product-logo">
              <img src={ProductShiftImg} alt="" className="logo-shift" />
            </div>
            <div className="product-text">
              <h3>Real-Time Voice Converter</h3>
              <p>{t('tech2')}</p>
            </div>
          </div>

          <div className="product-wrap">
            <div className="product-logo">
              <img src={ProductClearImg} alt="" className="logo-clear" />
            </div>
            <div className="product-text">
              <h3>Real-Time Voice Separator</h3>
              <p>{t('tech3')}</p>
            </div>
          </div>

          <div className="product-wrap">
            <div
              className="product-text"
              style={{ display: 'flex', justifyContent: 'center' }}
            >
              <Link to="https://product.supertone.ai/" className="product-btn">
                {t('productBtn')}
              </Link>
            </div>
          </div>
        </div>
      </article>
    </section>
  );
};

export default Technology;
