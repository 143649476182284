import { useEffect, useRef } from 'react';
import $ from 'jquery';
import 'slick-carousel';
import { useTranslation } from 'react-i18next';

import 'slick-carousel/slick/slick.css';

import Logo from '../../common/assets/logos/logo-supertone-ai.svg';
import { Link } from 'react-router-dom';

const Header = () => {
  const { i18n } = useTranslation();
  const wrapperRef = useRef<HTMLDivElement>(null);
  const progressRef = useRef<HTMLDivElement>(null);

  const onLanguageChangeClick = () => {
    const lang = i18n.resolvedLanguage;

    if (lang === 'en') {
      i18n.changeLanguage('ko');
    } else {
      i18n.changeLanguage('en');
    }
  };

  // Menu slider for mobile.
  useEffect(() => {
    const wrapper = wrapperRef.current;
    if (!wrapper) return;

    $(wrapper).slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      dots: false,
      autoplay: true,
      autoplaySpeed: 1500,
      vertical: true,
      draggable: false,
      pauseOnFocus: false,
    });

    return () => {
      $(wrapper).slick('unslick');
    };
  }, []);

  // Scroll progress bar.
  useEffect(() => {
    const onScroll = () => {
      if (!progressRef.current) return;

      const winScroll =
        document.body.scrollTop || document.documentElement.scrollTop;
      const height =
        document.documentElement.scrollHeight -
        document.documentElement.clientHeight;
      const scrolled = (winScroll / height) * 100;

      progressRef.current.style.width = scrolled + '%';
    };

    window.addEventListener('scroll', onScroll);

    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  });

  return (
    <header>
      <article>
        <Link to="/">
          <img src={Logo} alt="" className="logo" />
        </Link>

        <div className="header-rolling post-slider only-m">
          <div ref={wrapperRef} className="post-wrapper">
            <div className="post">
              <Link to="https://product.supertone.ai">Products</Link>
            </div>
            <div className="post">
              <Link to="/people">Company</Link>
            </div>
            <div className="post">
              <Link to="/media">Media</Link>
            </div>
          </div>
        </div>

        <div className="menu">
          <Link to="https://product.supertone.ai">Products</Link>
          <Link to="/people">Company</Link>
          <Link to="/media">Media</Link>
        </div>

        <div id="lang" className="lang" onClick={onLanguageChangeClick}>
          {i18n.resolvedLanguage === 'en' ? '가' : 'A'}
        </div>
      </article>

      <div className="progress-wrap">
        <div ref={progressRef} className="progress"></div>
      </div>
    </header>
  );
};

export default Header;
