import { useTranslation } from 'react-i18next';

const Contact = () => {
  const { t } = useTranslation();

  return (
    <>
      {/* Mobile */}
      <section className="contact only-m">
        <article>
          <div className="title">
            <h1>
              Let’s Create
              <br />
              Something New
              <br />
              Together.
            </h1>
            <p>{t('contact1')}</p>
          </div>
          <div className="process">
            <h1>Process</h1>
            <div className="steps">
              <ul>
                <li>
                  <div>1</div>
                  <h3>{t('contact2')}</h3>
                  <p>{t('contact3')}</p>
                </li>
                <li>
                  <div>2</div>
                  <h3>{t('contact4')}</h3>
                  <p>{t('contact5')}</p>
                </li>
                <li>
                  <div>3</div>
                  <h3>{t('contact6')}</h3>
                  <p>{t('contact7')}</p>
                </li>
                <li>
                  <div>4</div>
                  <h3>{t('contact8')}</h3>
                  <p>{t('contact9')}</p>
                </li>
                <li>
                  <div>5</div>
                  <h3>{t('contact10')}</h3>
                </li>
              </ul>
            </div>
            <div className="necessity">
              <h2>{t('contact11')}</h2>
              <p>{t('contact12')}</p>
              <a
                href="//supertone.notion.site/Voice-source-quality-conditions-Supertone-4ef6d038a756488fa1310bab630f0114"
                target="_blank"
                rel="noreferrer"
              >
                {t('contact13')}
              </a>
              <p>{t('contact14')}</p>
              <p>{t('contact15')}</p>
            </div>
          </div>
          <div className="more">
            <p>{t('contact16')}</p>
            <a
              href="https://forms.gle/H8tqHDLLZXqkaT7K9"
              target="_blank"
              rel="noreferrer"
            >
              Fill out the Brief
            </a>
          </div>
        </article>
      </section>

      {/* Desktop */}
      <section className="contact only-pc">
        <article>
          <div className="title">
            <h1>Let’s Create Something New Together.</h1>
            <p>{t('contact1')}</p>
          </div>

          <div className="process">
            <h2>Process</h2>
            <div className="necessity">
              <h2>{t('contact11')}</h2>
              <div className="necessity-inner-wrap">
                <div className="necessity-inner">
                  <p>{t('contact12')}</p>
                  <a
                    href="//supertone.notion.site/Voice-source-quality-conditions-Supertone-4ef6d038a756488fa1310bab630f0114"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {t('contact13')}
                  </a>
                </div>
                <div className="necessity-inner">{t('contact14')}</div>
                <div className="necessity-inner">{t('contact15')}</div>
              </div>
            </div>

            <div className="steps">
              <ul className="step-number">
                <li>
                  <div className="step-left">1</div>
                </li>
                <li>
                  <div className="step-left">2</div>
                </li>
                <li>
                  <div className="step-left">3</div>
                </li>
                <li>
                  <div className="step-left">4</div>
                  <div className="step-5">5</div>
                </li>
              </ul>

              <ul>
                <li>
                  <h3 className="step-left">{t('contact2')}</h3>
                  <p className="step-left">{t('contact3')}</p>
                </li>
                <li>
                  <h3 className="step-left">{t('contact4')}</h3>
                  <p className="step-left">{t('contact5')}</p>
                </li>
                <li>
                  <h3 className="step-left">{t('contact6')}</h3>
                  <p className="step-left">{t('contact7')}</p>
                </li>
                <li>
                  <h3 className="step-left">{t('contact8')}</h3>
                  <p className="step-left">{t('contact9')}</p>
                  <h3 className="step-5">{t('contact10')}</h3>
                </li>
              </ul>
            </div>

            <div className="process-bottom">
              <div className="brief-wrap">
                <p>{t('contact16')}</p>
                <a
                  href="https://forms.gle/H8tqHDLLZXqkaT7K9"
                  target="_blank"
                  rel="noreferrer"
                  className="business-contact"
                >
                  Fill out the Brief
                </a>
              </div>
            </div>
          </div>
        </article>
      </section>
    </>
  );
};

export default Contact;
