import { useTranslation } from 'react-i18next';

const Footer = () => {
  const { t } = useTranslation();

  return (
    <footer>
      <article>
        <div>
          {t('address')}
          <a href={t('mapUrl')} target="_blank" rel="noreferrer">
            {t('showMap')}
          </a>
        </div>
        <div>
          © {new Date().getFullYear()} Supertone Inc, All Rights Reserved
        </div>
      </article>
    </footer>
  );
};

export default Footer;
